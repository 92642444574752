import './Status.css'; 

import React from 'react';

function Status({status, text}) {

  return (
    <div className={"status status-"+status}> 
      <div className='status-dot'/>
      <div className='status-doughnut'/>
      <div className='status-label'> {text} </div>
    </div>
  );
}

export default Status;
