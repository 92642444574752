import './App.css';
import React, { useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import moment from 'moment';

import twitterX from './twitter-x.png';
import copyClip from './copy-clip.png';
import screenshot from './screenshot.png';
import magnifingGlass from './magnifying-glass.png';
import deleteIcon from './delete.png';

import Status from './Status';

const tldrOptions = {
  "positive": ["true", "accurate", "correct", "verifiable", "real", "genuine", "trusty", "truthful"],
  "negative": ["false", "lie", "lying", "not true", "untrue", "sham", "tale", "fib", "falsity", "falsehood", "misleading", "deceitful", "deceptive", "confusing", "deceiving", "delusive", "delusory", "fallacious", "specious", "cheating", "illusory"],
  "neutral": []
};

const tldrValues = {
  "positive": "positive",
  "negative": "negative",
  "neutral": "nutral"
};

const placeholderFacts = [
  {id:"the-great-wall-of-china-is-visible-from-space",title:"The Great Wall of China is Visible from Space",state:"False"},
  {id:"vikings-wore-horned-helmets",title:"Vikings Wore Horned Helmets",state:"False"},
  {id:"humans-have-five-senses",title:"Humans Have Five Senses",state:"False"},
  {id:"einstein-failed-mathematics",title:"Einstein Failed Mathematics",state:"False"},
  {id:"we-only-use-10-percent-of-our-brains",title:"We Only Use 10 Percent of Our Brains",state:"False"}
]

let urlFact = window.location.pathname.substring(1);

function App() {
  const [theory, setTheory] = useState("");
  const [fact, setFact] = useState("");
  const [logoAnimation, setlogoAnimation] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [disableSearch, setDisableSearch] = useState(false);
  //const [mode, setMode] = useState(true); // true is funny
  const [inputScrollClass, setInputScrollClass] = useState("");

  const onChangeHandler = event => {
    setTheory(event.target.value);
  };

  useEffect(() => {
    if (urlFact) {
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            try {
              if (xhr.status === 500) {
                setFact({
                  error: "We coudn't find what you're searching, try something else..."
                });
              } else {
                let responseValue = JSON.parse(this.response);

                if (responseValue.sources && (typeof responseValue.sources === "string")) {
                  responseValue.sources = JSON.parse(responseValue.sources);
                }

                setFact(responseValue);
                setTheory(responseValue.theory)
                document.querySelector('meta[name="description"]').setAttribute("content", responseValue.tldr.trim() + "! " + responseValue.x.trim());
              }
              setDisableSearch(false);
              setShowSearch(false);
            } catch (parseError) {
              console.log('Error parsing JSON response: ' + parseError);
            }
          }
      };
      xhr.open("POST", 'https://rt09w8q66h.execute-api.us-east-1.amazonaws.com/', true);
      xhr.timeout = 90000; // time in milliseconds
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('X-Referer', window.location.href);
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.send(JSON.stringify({
        key: urlFact
      }));
      urlFact = null;
    }
  },[]);

  useEffect(() => {
    setShowSearch(true);
    if (theory) {
      setInputScrollClass("");
      let textElement = document.querySelectorAll("textarea")[0];
      setInputScrollClass("input-scroll-"+ (((textElement.scrollHeight/42) >= 3) ? 3 : (Math.ceil(textElement.scrollHeight/42))));
    } else {
      setInputScrollClass("input-scroll-1");
    }
  },[theory]);

  function handle(e){

    if (theory.trim()==="") {
      return;
    }
    if (disableSearch) {
      return;
    }

    if(!e.key || e.key === "Enter"){
      setFact("");
      setDisableSearch(true);
      e.preventDefault(); // Ensure it is only this code that runs
      var xhr = new XMLHttpRequest();
      setlogoAnimation(true);
      xhr.onreadystatechange = function () {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            try {
              if (xhr.status === 500) {
                setFact({
                  error: "Try again later..."
                });
              } else {
                let responseValue = JSON.parse(this.response)
                setFact(responseValue);
                window.history.pushState({}, "", '/'+responseValue.key);
                document.querySelector('meta[name="description"]').setAttribute("content", responseValue.tldr.trim() + "! " + responseValue.x.trim());
              }
              setDisableSearch(false);
              setShowSearch(false);
            } catch (parseError) {
              console.log('Error parsing JSON response: ' + parseError);
            }
            setlogoAnimation(false);
          }
      };
      xhr.open("POST", 'https://rt09w8q66h.execute-api.us-east-1.amazonaws.com/', true);
      xhr.timeout = 90000; // time in milliseconds
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('X-Referer', window.location.href);
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader('Content-Type', 'application/json');

      xhr.send(JSON.stringify({
        theory: theory
      }));
    }
  }

  function copyText(URL) {
    let textToCopy;
    if (URL) {
      textToCopy = window.location.href;
      toast.success('Link copied to clipboard');
    } else {
      textToCopy = "*" + fact.theory + "*\n\n**" + fact.tldr.trim() + "**\n\n" + fact.x + "" + fact.sum + "\n\nhttps://www.askreally.com" ;
      toast.success('Text copied to clipboard');
    }
    navigator.clipboard.writeText(textToCopy);

  }

  let tldr = tldrValues.neutral;
  if (fact && fact.tldr) {
    let tldrText = fact.tldr.trim().toLowerCase();
    if (tldrOptions.negative.includes(tldrText)) { //try negative first due to not true
      tldr = tldrValues.negative;
    } else if (tldrOptions.positive.includes(tldrText)) {
      tldr = tldrValues.positive;
    }
  }

  let factTime = null;
  if (fact && fact.timestamp) {
    factTime = moment(parseInt(fact.timestamp)).format('MMMM Do YYYY, hh:mm a');
    factTime = "As of " + factTime.toLocaleString();
  }

  //twitter link
  let twitter_link = "https://twitter.com/intent/tweet?text="+fact.x+" @Ask_Really";

  return (
    <div className="App">
      <div className="header-container">
        <a href="https://www.askreally.com"><div className={logoAnimation ? "logo logo-animation" : "logo"} /></a>
        <h1 className="title">rea!!y?</h1>
        <h3 className="sub-title"> Verify whenever you're in doubt </h3>
      </div>
      <div className="input-containter">
        <img className="magnifying-glass" src={magnifingGlass} alt="search"/>
        {theory ? <img className="clean-input" alt="Clear theory" src={deleteIcon} onClick={()=>{setTheory("")}} /> : ""}
        <textarea placeholder="Enter text to fact check in any language..." className={'theory ' +inputScrollClass+ (showSearch ? '' :' padding-fix')} onChange={onChangeHandler} onKeyPress={handle} value={theory}></textarea>
        <div className={'button' + (showSearch ? '' :' hide-search') + (disableSearch? ' disable-search' : '')} onClick={handle}> Really? </div>
      </div>
      <div className="input-containter">
        {factTime ?
            <div className="fact-timestamp">
              {factTime}
            </div>
          : ""}
      </div>

      {/*
        <div className="responseStyle">
          <img src={mode ? serious_disabled : serious} onClick={(e)=>{setMode(false);}} alt="serious mode" />
          <img src={mode ? funny : funny_disabled} onClick={(e)=>{setMode(true);}} alt="funny mode" />
        </div>
        {
        (fact.indexOf(seperator)===-1) ? fact :
          (mode) ? fact.split(seperator)[1].trim() : fact.split(seperator)[0].trim()
        }
      */}

      {(!fact && !urlFact) ? <div className="placeholder-facts">
        {placeholderFacts.map(item=>
          <div className="placehodlder-text" id={item.id} onClick={()=>{window.location.href = item.id;}}>
            {item.title}
            <span className="placehodlder-stat">{item.state}</span>
        </div>)}
      </div> : ""}

      {fact && fact.error ?
        <div className="fact-container">
          <div className="fact fact-title"> {fact.error} </div>
        </div>
      : ""}

      {fact && !fact.error ?
        <div className="fact-container">
          <Status status={tldr} text={(fact.tldr ? fact.tldr.trim().replace(/\.$/, "") : "")}/>
          <div className="icons-container-top">
            <br />
            <img className="copy-button share-url" src={screenshot} onClick={()=>{copyText(true)}} title="Share Link" alt="Share Link" />
            <img className="copy-button copy-clip" src={copyClip} onClick={()=>{copyText(false)}} title="Copy all text to clipboard" alt="Copy all text to clipboard" />
            <a href={twitter_link}><img className="copy-button twitter-button" src={twitterX} title="Share on X" alt="Copy X text to clipboard" /> </a>
          </div>
          <br />
          <div className="fact fact-x"> {fact.x} </div>
          <div className="fact fact-sum"> {fact.sum} </div>

          <div className="icons-container-bottom">
            <br />
            <img className="copy-button share-url" src={screenshot} onClick={()=>{copyText(true)}} title="Share Link" alt="Share Link" />
            <img className="copy-button copy-clip" src={copyClip} onClick={()=>{copyText(false)}} title="Copy all text to clipboard" alt="Copy all text to clipboard" />
            <a href={twitter_link}><img className="copy-button twitter-button" src={twitterX} title="Share on X" alt="Copy X text to clipboard" /> </a>
          </div>
        </div>
      : ""}

      {fact && fact.sources ? <div className="source-container">
          <div className="fact-sources-seperator"/>
          <div className="fact-sources">{fact && fact.sources ? fact.sources.map((source, id)=> <a href={source.url} key={id} target="_blank" className="fact-card">
              <img src={source.profile.img} className="fact-image"/>
              <div className="fact-content">
                <div className="fact-card-title">{source.profile.name}</div>
                <div className="fact-card-content">{source.title}</div>
              </div>
            </a>) : ""}
          </div>
        </div> : ""}

        {/*<div className="fact-card-content">{source.content}</div>*/}

      <div className="footer"> - We are entitled to our own opinions, but not our own facts - </div>

      <Toaster />
    </div>
  );
}

export default App;
